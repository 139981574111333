import React from "react";
import URLs from "../../../static/data/urls.json";
import ExternalLink from "../../components/external-link";
import * as styles from "./ie.module.scss";


const IeSection = () => {
  return (
    <div className={styles.container}>
      <main>
        <h1>
          Sorry, this site isn't compatible with Internet Explorer. Please visit
          with{" "}
          <a href={"https://www.microsoft.com/en-us/edge"}>Microsoft Edge</a>,{" "}
          <a href={"https://www.google.com/chrome"}>Google Chrome</a>, or{" "}
          <a href={"https://www.mozilla.org/firefox"}>Firefox</a>.
        </h1>
        <h2>Thank you for supporting the KGW Great Toy Drive</h2>
        <div className={styles.buttons}>
          <p>
            <ExternalLink href={URLs.buy_supplies_url}>
              Click here to purchase directly from Schoolhouse Supplies
            </ExternalLink>
          </p>
          <p>
            <ExternalLink href={URLs.donate_cash_url}>
              Click here to donate cash online
            </ExternalLink>
          </p>
        </div>
      </main>
    </div>
  );
};
export default IeSection;
