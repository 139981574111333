import React from "react";

const ExternalLink = (props) => {
  const { href, children } = props;
  return (
    <a href={href} target={"_blank"} rel="noreferrer">
      {children}
    </a>
  );
};
export default ExternalLink;
